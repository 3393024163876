import React from 'react'
import { useState } from 'react';

// import NavBar from '../component/NavBar'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../component/NavBar.css';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaSearch, FaHeart, FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { FaBell, FaShoppingCart, FaUserCircle } from 'react-icons/fa'; // Import required icons
import CarousulComponent from '../component/CarousulBanner/CarousulComponent';
import Footer from '../component/Footer/Footer';

export default function LandingPage() {
    const [activeButton, setActiveButton] = useState(1);
    const handleButtonClick = (buttonId) => {
        // Set the clicked button as active
        setActiveButton(buttonId);
    };
    return (
        <div className='col-sm-12'>
            <div className='text-center p-2' style={{ backgroundColor: '#262F63', color: '#fff' }}>
                🚚Livraison offerte dès 49€ d'achat. 💳Paiement 3x sans frais dès 99€ d'achat.
            </div>
            <div>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="w-100 justify-content-between" variant='tabs'>
                                <Nav.Link href="#Pharmacies de garde" >Pharmacies de garde</Nav.Link>
                                <Nav.Link href="#Assurances">Assurances</Nav.Link>
                                <Nav.Link href="#Notice et prix des médicaments">Notice et prix des médicaments</Nav.Link>
                                <Nav.Link href="#Recherche de médicaments">Recherche de médicaments</Nav.Link>
                                <Nav.Link href="#Portefeuille électronique">Portefeuille électronique</Nav.Link>
                                <Nav.Link href="#Vaccination">Vaccination</Nav.Link>
                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <div className='d-flex flex-column flex-lg-row justify-content-evenly align-items-center px-4 px-lg-5 py-2 ' style={{ borderBottom: '1px solid #DEDEDE' }} >
                <div className='d-flex justify-content-evenly  col-lg-4 col-12  align-items-center mb-2 mb-lg-0' >
                    <div className='d-flex  align-items-center justify-content-evenly py-2 '>
                        <div className='col-6'>
                            <img height={'50px'} width={'120px'} src={require('../assets/logo.png')} />
                        </div>
                        <div className='px-2 col-6'>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="text-dropdown-toggle">
                                    Shop by Category
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item >Action</Dropdown.Item>
                                    <Dropdown.Item >Another action</Dropdown.Item>
                                    <Dropdown.Item >Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className='d-flex col-sm-12 col-md-4 justify-content-center align-items-center mb-2 mb-lg-0 '  >
                    <InputGroup  >
                        <InputGroup.Text className="no-border">
                            <FaSearch />
                        </InputGroup.Text>
                        <Form.Control aria-label="Text input with dropdown button" />
                        <DropdownButton
                            variant="outline-secondary"
                            title="All Category"
                            id="input-group-dropdown-2"
                            align="end"
                        >
                            <Dropdown.Item href="#">Action</Dropdown.Item>
                            <Dropdown.Item href="#">Another action</Dropdown.Item>
                            <Dropdown.Item href="#">Something else here</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                </div>

                <div className='d-flex justify-content-between justify-content-md-evenly col-lg-4 col-12 align-items-center ' >
                    <div><FaUserCircle /> connexion</div>
                    <div><FaShoppingCart /> cart</div>
                    <div><FaBell /> Notification</div>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-start justify-content-lg-evenly align-items-center row px-4 mx-0 mx-lg-5 py-2">
                {/* First Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(1) }} className={activeButton === 1 ? 'btn-primary' : 'btn-outline-secondary'}>Bon plan</div>
                </div>

                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(2) }} className={activeButton === 2 ? 'btn-primary' : 'btn-outline-secondary'}>Santé</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(3) }} className={activeButton === 3 ? 'btn-primary' : 'btn-outline-secondary'}>Hygiène</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(4) }} className={activeButton === 4 ? 'btn-primary' : 'btn-outline-secondary'}>Visage</div>
                </div>
                {/* Second Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(5) }} className={activeButton === 5 ? 'btn-primary' : 'btn-outline-secondary'}>Corps</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(6) }} className={activeButton === 6 ? 'btn-primary' : 'btn-outline-secondary'}>Cheveux</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(7) }} className={activeButton === 7 ? 'btn-primary' : 'btn-outline-secondary'}>Nutrition</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(8) }} className={activeButton === 8 ? 'btn-primary' : 'btn-outline-secondary'}>Bébé</div>
                </div>
                {/* Third Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(9) }} className={activeButton === 9 ? 'btn-primary' : 'btn-outline-secondary'}>Bio</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(10) }} className={activeButton === 10 ? 'btn-primary' : 'btn-outline-secondary'}>Solaires</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(11) }} className={activeButton === 11 ? 'btn-primary' : 'btn-outline-secondary'}>Vétérinaires</div>
                </div>
            </div>

            <CarousulComponent />
            <div className='d-flex justify-content-between py-2 px-5' style={{ marginTop: '15px' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>MOST PURCHASED PRODUCTS</div>
                <div><a href="#">View More</a></div>
            </div>
            <div className='d-flex flex-column align-items-center flex-md-row py-2 px-5  justify-content-sm-center justify-content-lg-between'>
                {
                    [1, 2, 3, 4, 5].map((item, index) => (
                        <div key={index} class="card text-center shadow-sm responsive-width " style={{ marginBottom: '10px' }}>
                            <div className='d-flex justify-content-between , ' style={{ padding: 5 }}>
                                <div style={{ fontSize: '10px', backgroundColor: '#F9595F', textAlign: 'center', padding: 3, borderRadius: 3 }}>-10% off</div>
                                <div><FaHeart style={{ fontSize: '20px', color: '#F9595F' }} /></div>
                            </div>
                            <img src={require('../assets/cardimg.png')} class="card-img" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Avène</h5>
                                <p class="card-text" style={{ fontSize: '11px' }}>CICALFATE+ - Crème Réparatrice Protectrice - Visage et Corps - Peaux Sensibles Irritées, 40ml</p>
                                <p class="card-text" style={{ fontSize: '11px', fontWeight: 'bold' }}>7,49 €  <i

                                    className={`bi bi-star-fill fs-7 text-warning `}
                                /><i

                                        className={`bi bi-star-fill fs-7 text-warning m-1`}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning `}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning m-1`}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning `}
                                    />(21)</p>
                                <a href="#" class="btn w-100" style={{ backgroundColor: '#94C11F', borderRadius: '20px', color: '#fff' }}>Add to Cart</a>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='d-flex justify-content-between py-2 px-5' style={{ marginTop: '15px' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>GOOD DEALS</div>
                <div><a href="#">View More</a></div>
            </div>

            <div className='row px-5 g-0'>
                <div className='col-6 p-1'>
                    <img src={require('../assets/5900784.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../assets/9549650.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../assets/1026499.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../assets/9954293.png')} width={'50%'} class="card-img" alt="..." />
                </div>

            </div>

            <div className='d-flex justify-content-between py-2 px-5' style={{ marginTop: '15px' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Articles or Blogs</div>
                <div><a href="#">View More</a></div>
            </div>
            <div className='d-flex flex-column align-items-center flex-md-row py-2 px-5  justify-content-sm-center justify-content-lg-between'>
                {
                    [1, 2, 3, 4].map((item, index) => (
                        <div key={index} class="card text-center shadow-sm responsive-width1" style={{ marginBottom: '10px' }}>
                            <img src={require(index == 0 || index == 2 ? '../assets/successful-medical-team.png' : '../assets/collection-medicine-bottles-including-one-that-sayssyringe.png')} class="card-img" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-start">Blog title</h5>
                                <p class="card-text text-start" style={{ fontSize: '11px' }}>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod.....</p>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <p class="card-text" style={{ fontSize: '11px', textAlign: 'center' }}>10 Nov 2025</p>
                                    </div>
                                    <a href="#" class="btn " style={{ backgroundColor: '#94C11F', borderRadius: '10px', color: '#fff', paddingLeft: '20px', paddingRight: '20px', paddingTop: '2px' }}>Read</a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='d-flex justify-content-between my-2 mx-2 mx-lg-5 py-3 px-2 align-items-center row ' style={{ backgroundColor: '#262F63' }}>
                <div className='text-white fs-1 col-sm-12 col-lg-4 ps-5'>Subscribe to the NEWSLETTER</div>
                <div className='text-white col-sm-12 col-lg-4 ps-5 ps-lg-0 '>and enjoy more boticinal tips and offers</div>
                <div className='col-sm-12 col-lg-4 px-5 px-lg-0 py-3 py-lg-0'> <InputGroup className="text-white">
                    <Form.Control
                        placeholder="Enter your email..."
                        aria-label="Enter your email..."
                        aria-describedby="basic-addon2"
                    />
                    <Button variant="outline-secondary" id="button-addon2" style={{ backgroundColor: '#94C11F', }}>
                        Subscribe
                    </Button>
                </InputGroup>
                </div>
            </div>

            <div className='d-flex flex-column flex-lg-row justify-content-evenly  my-5  py-3 px-5 shadow-lg '>
                <div className='text-center'>
                    <div className='py-2'><img src={require('../assets/Group1000003446.png')} alt="..." /></div>
                    <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>PRODUITS AUTHENTIQUES</div>
                    <div style={{ fontSize: '11px' }}>En Qualite Pharmaceutique</div>
                </div>
                <div className='text-center'>
                    <div className='py-2'><img src={require('../assets/Group1000003447.png')} alt="..." /></div>
                    <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>LIVRAISON RAPIDE</div>
                    <div style={{ fontSize: '11px' }}>24h a Abidjan et dans les regions</div>
                </div>
                <div className='text-center'>
                    <div className='py-2'><img src={require('../assets/Group1000003448.png')} alt="..." /></div>
                    <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>PAIEMENT AU CHOIX</div>
                    <div style={{ fontSize: '11px' }}>Cash Mobile Money - Carte bancaire</div>
                </div>
                <div className='text-center'>
                    <div className='py-2'><img src={require('../assets/Group1000003449.png')} alt="..." /></div>
                    <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>RETOURS SIMPLES & RAPIDES</div>
                    <div style={{ fontSize: '11px' }}>Pour les produits endommages ou non confrme</div>
                </div>
            </div>
            <div style={{ backgroundColor: '#ebedef' }}>
                <div className='text-center px-5 '>
                    <div>
                        <p style={{ color: '#262F63', fontSize: '28px', fontWeight: '700' }}>Articles or Blogs</p>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p className='w-sm-70 w-lg-50 col-12 col-sm-10 col-lg-6' style={{ fontSize: '16px' }}>
                            PharmaConsults vous permet de bénéficier de 7 services essentiels pour simplifier vos démarches de santé au quotidien
                        </p>
                    </div>
                </div>
                <div >
                    <div className='d-flex flex-column flex-lg-row justify-content-evenly bg-grey  py-sm-0 py-lg-3 px-5  ' >
                        <div className='text-center col-sm-12 col-lg-3  bg-white p-4 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group2.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Pharmacies de garde par commune</div>
                            <div style={{ fontSize: '11px' }}>Trouvez en un clin d’œil une pharmacie ouverte près de chez vous, où que vous soyez.</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 mx-sm-0 mx-lg-2 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group3.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Recherche de médicaments</div>
                            <div style={{ fontSize: '11px' }}>Vous avez une ordonnance ? Envoyez une requête pour vérifier la disponibilité de vos médicaments dans toutes les pharmacies de Côte d’Ivoire et réservez en un clic.</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group4.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Assurances acceptées </div>
                            <div style={{ fontSize: '11px' }}>Plus besoin de vous déplacer inutilement. Vérifiez à l'avance quelles pharmacies acceptent votre assurance santé</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 mx-sm-0 mx-lg-2 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group5.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Portefeuille électronique </div>
                            <div style={{ fontSize: '11px' }}>Fini les soucis de petite monnaie ! Recevez votre reste de monnaie directement sur votre téléphone et utilisez-le dans n’importe quelle pharmacie partenaire.
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row justify-content-center   py-sm-0 py-lg-3 px-5 '>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img style={{ backgroundColor: '#94C11F' , padding:12 , borderRadius:'50px' }} src={require('../assets/conversation1.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Fiche et prix des médicaments </div>
                            <div style={{ fontSize: '11px' }}>Consultez les prix et les fiches complètes de vos médicaments avant de vous rendre en pharmacie.</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 mx-sm-0 mx-lg-2 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group1.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Vaccination</div>
                            <div style={{ fontSize: '11px' }}>Ne manquez plus aucun vaccin ! Suivez vos calendriers vaccinaux, activez des rappels automatiques et consultez les prix des vaccins en quelques clics</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 my-2 my-lg-0' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../assets/Group6.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Marketplace de produits cosmétiques </div>
                            <div style={{ fontSize: '11px' }}>Prenez soin de vous avec notre boutique en ligne dédiée aux produits cosmétiques et de parapharmacie.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className='my-5  py-3 px-5 mx-0 mx-lg-5' style={{ backgroundColor: '#ebedef' }}>
                <div class="card mb-3 border-0 mx-0 mx-lg-4" style={{ backgroundColor: '#ebedef' }} >
                    <div class="row g-0">
                        <div class="col-md-8">
                            <div class="card-body" style={{ fontSize: '14px' }}>
                                <p class="card-text">PharmaConsults vous permet de bénéficier de 7 services essentiels pour simplifier vos démarches de santé au quotidien :
                                </p>
                                <p>
                                    <strong>⦁	 Pharmacies de garde par commune :</strong> Trouvez en un clin d’œil une pharmacie ouverte près de chez vous, où que vous soyez.
                                </p>
                                <p>
                                    <strong>⦁	 Recherche de médicaments :</strong> Vous avez une ordonnance ? Envoyez une requête pour vérifier la disponibilité de vos médicaments dans toutes les pharmacies de Côte d’Ivoire et réservez en un clic.
                                </p>
                                <p>
                                    <strong>⦁	Assurances acceptées :</strong> Plus besoin de vous déplacer inutilement. Vérifiez à l'avance quelles pharmacies acceptent votre assurance santé.
                                </p>
                                <p>
                                    <strong>⦁	Portefeuille électronique :</strong> Fini les soucis de petite monnaie ! Recevez votre reste de monnaie directement sur votre téléphone et utilisez-le dans n’importe quelle pharmacie partenaire
                                </p>
                                <p>
                                    <strong>⦁	Fiche et prix des médicaments :</strong> Consultez les prix et les fiches complètes de vos médicaments avant de vous rendre en pharmacie.
                                </p>
                                <p>
                                    <strong>⦁	Marketplace de produits cosmétiques :</strong> Prenez soin de vous avec notre boutique en ligne dédiée aux produits cosmétiques et de parapharmacie.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 py-3">
                            <img src={require('../assets/Group1000003453.png')} class="img-fluid rounded-start" alt="..." />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='' style={{ backgroundColor: '#FFFFFF' }}>
                <p className='text-center px-5'><span style={{ color: "#262F63", fontWeight: 'bold' }}>Service client :</span> 0225 0708592565 / 2722252547 du lundi au vendredi de 08h30 à 18h30 et le samedi de 09h à 17h  </p>
                <p className='text-center'><span style={{ color: "#262F63", fontWeight: 'bold' }}>Mail :</span> serviceclient@pharma-consults.com </p>
            </div>
            <hr />
            <Footer />
            <div className='text-center p-2' style={{ backgroundColor: '#262F63', color: '#fff' }}>
                ©2024 PharmaConsult Dotcom - Tous droits réservés - Image non libre de droits
            </div>
        </div >


    )
}
