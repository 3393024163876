import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer class="py-4 px-4 ">
                <div style={{ padding: '0px 110px 0px 110px', marginBottom: '20px' }}> <img height={'75px'} width={'180px'} src={require('../../assets/logo.png')} /></div>
                <div class="container text-center">

                    <div class="row">
                        <div class="col-md-3  d-flex flex-column  align-items-center mb-4">

                            <ul class="list-unstyled">
                                <li><h4 style={{ color: '#262F63', }}>ACCES RAPIDES</h4></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Contact-nous</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Mon compte</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Programme fidelite</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Rappel Produits</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Une question sur un produit ?</a></li>

                            </ul>
                        </div>

                        <div class="col-md-3 d-flex flex-column  align-items-center mb-4">
                            <ul class="list-unstyled">
                                <li><h4 style={{ color: '#262F63', }}>INFOS PRATIQUES</h4></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>FAQ</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Conditions generales de vente</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Frais et delais de livraison</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Retour et remboursement</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Traitement et protection ddos donnoos persannallas</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Moyens de paiement</a></li>

                            </ul>
                        </div>

                        <div class="col-md-3 d-flex flex-column  align-items-center mb-4">

                            <ul class="list-unstyled">
                                <li><h4 style={{ color: '#262F63', }}>NOUS CONNAITRE</h4></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Qui sommes-nous</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Nos engagements</a></li>

                            </ul>
                        </div>

                        <div class="col-md-3 d-flex flex-column  align-items-center">

                            <ul class="list-unstyled">
                                <li><h4 style={{ color: '#262F63', }}>NOS PRODUITS</h4></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Nas pramotions</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Nas Bons Plans</a></li>
                                <li><a href="#" class="text-dark text-decoration-none" style={{ fontSize: '12px' }}>Nas Nousveautes</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center my-2 mx-0 mx-sm-5 py-3 px-0 px-sm-5'>
                    <div className=' d-flex flex-row flex-lg-column justify-content-center align-items-center'>
                        <div style={{ fontWeight: '500', padding: '7px', fontSize: '17px', color: '#262F63' }} >Our Socials</div>
                        <div className='d-flex' >
                            <div className='px-1'><img src={require('../../assets/facebook.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/twitter.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/instagram.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/youtube.png')} /></div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='px-1'>
                            <img src={require('../../assets/visa.png')} /></div>
                        <div className='px-1'><img src={require('../../assets/mastercard.png')} /></div>
                        <div className='px-1'><img src={require('../../assets/lock.png')} /></div >
                        <div className='px-1'>Paiement 100% sécurisé</div></div>
                </div>
            </footer>

        </div>

    )
}
